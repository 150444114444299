import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import { SVGCardano, SVGLink, SVGInfo } from "@/svg"
import * as style from "./style.module.scss"

const Promo = () => {
  const pools = useSelector((state) => state.settings.pools)
  const rate = pools?.nextRate

  return (
    <div className="tada__block">
      <div className={style.banner}>
        <div className="tada__left tada__left--orange mb-5">
          <div className={style.title}>
            <h1 className="mb-0" data-aos="fade-up" data-aos-delay="0">
              <strong>Welcome to TADATek</strong>
            </h1>
          </div>
        </div>
        <img
          src="/resources/banner/tadatek-vuong-light.svg"
          className={style.image}
          alt=""
        />
        <div className="tada__left tada__left--empty">
          <div
            className={style.content}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <p>
              TADATek is a decentralized infrastructure for Cardano Ecosystem.
              Our mission is to transit people into a real decentralized
              blockchain on Cardano.
            </p>
            <p>
              Stake ADA in TADA pools and get <strong>~5% ROI</strong> with
              extra rewards each epoch 1{" "}
              <strong className="tada__ticker">TADA</strong> per{" "}
              {rate / 1000000} <strong className="tada__ticker">ADA</strong>{" "}
              staked.
            </p>
            <p className="mb-5 pb-0 pb-md-5">
              Own a part of our network and share the rewards.
            </p>
            <div data-aos="fade-up" data-aos-delay="600">
              <Link
                to="/tada/distribution/"
                className="tada__btn tada__btn--round me-3 mb-3 tada__btn--white"
              >
                <span className="me-2">TADA ISPO</span>
                <span className="tada__link">
                  <SVGInfo />
                </span>
              </Link>
              <Link
                to="/stake/pools/"
                className="tada__btn tada__btn--round me-3 mb-3 tada__btn--white"
              >
                Stake Pools
              </Link>
              <Link
                to="/stake/nft/"
                className="tada__btn tada__btn--round me-3 mb-3 tada__btn--white"
              >
                Mint Assets
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="tada__promo">
        <div>
          <h1 className="tada__promo__text" data-aos="fade-up" data-aos-delay="0">
            Hello, we’re TADATek!<br /> An advanced DeFi ecosystem for{" "}
            <span className="text-nowrap">
              <span className="tada__promo__cardano">
                <SVGCardano />
              </span>{" "}
              Cardano
            </span>{" "}
            blockchain platform.
          </h1>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <p>
            We provide the whole range of blockchain solutions for Cardano: from
            DeFi exchange, API provisioning, light wallets, minting tools &
            explorers, to different SDKs that open the blockchain world to
            thousands of crypto-enthusiasts from all over the world. It's all
            about ADA DeFi finances in one place!
          </p>
          <p className="mb-5">
            Stake ADA in TADA pools and get <strong>~5% ROI</strong> with extra
            rewards each epoch 1 <strong className="tada__ticker">TADA</strong>{" "}
            per {rate / 1000000} <strong className="tada__ticker">ADA</strong> staked.
          </p>
        </div>
        <div data-aos="fade-up" data-aos-delay="600">
          <Link
            to="/tada/distribution/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            TADA Fair Launch
          </Link>
          <Link
            to="/xdiamond/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            VNI
          </Link>
          <a
            href="https://stake.tadatek.com"
            target="_blank"
            rel="noopener noreferrer"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            <span className="me-2">TADAStake App</span>
            <span className="tada__icon">
              <SVGLink />
            </span>
          </a>
          <Link
            to="/stake/calculator/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Stake Calculator
          </Link>
          <Link
            to="/stake/pools/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Stake Pools
          </Link>
        </div>
      </div>*/}
    </div>
  )
}

export default Promo
