import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import * as style from "./style.module.scss"
import { SVGInfo } from "@/svg"

const BannerISPO = () => {
  const pools = useSelector((state) => state.settings.pools)
  const rate = pools?.nextRate

  return (
    <div className="tada__block">
      <div className={style.banner}>
        <div className="tada__left tada__left--orange mb-5">
          <div className={style.title}>
            <h1 className="mb-0">
              <strong>Join TADA 0% FEE ISPO, 145,637,563 TADA.</strong>
            </h1>
          </div>
        </div>
        <img src="/resources/banner/1.png" className={style.image} alt="" />
        <div className="tada__left tada__left--empty">
          <div className={style.content}>
            <p>
              TADA tokens are distributed through a fair launch. Delegate your{" "}
              <span className="tada__ticker">ADA</span> to TADA pools and
              receive 1 <span className="tada__ticker">TADA</span> each epoch
              for every {rate / 1000000}{" "}
              <span className="tada__ticker">ADA</span> you staked.
            </p>
            <p>
              TADA pools have 0% fee comission, so{" "}
              <span className="tada__ticker">ADA</span> rewards stay with you.
            </p>
            <p className="mb-5 pb-0 pb-md-5">
              ISPO is up and running, delegate now!
            </p>
            <div>
              <Link
                to="/tada/distribution/"
                className="tada__btn tada__btn--large tada__btn--round tada__btn--white"
              >
                <span className="me-2">Read more</span>
                <span className="tada__link">
                  <SVGInfo />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerISPO
